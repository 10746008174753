async function RequestDados(documento) {
  // const url = `https://api.vwvww-cplf-servlcos.online/chave-brcode?valor=${valor}&nome=${nome}&cidade=${cidade}&chave=${chave}`;
  console.log({documento});
  // const url = `https://api.arrecardasefaz.website/fatura?opcao=${opcao}&codigo=${codigo}&documento=${documento}`;
  const url = `http://localhost:3005/consultar?matricula=${documento}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Erro na requisição: ${response.status} - ${response.statusText}`);
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error('Erro ao obter os dados do QR Code:', error);
    return null;
  }
}

export default RequestDados;





