import React, { useState } from 'react';

function Caema() {
  const [responseMessage, setResponseMessage] = useState('');

  const handleRequest = async () => {
    try {
      const response = await fetch('https://copasaportalprd.azurewebsites.net/Copasa.Portal/Login/EncryptLogin', {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/javascript, */*; q=0.01',
          'Accept-Language': 'pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7',
          'Connection': 'keep-alive',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'Cookie': '.AspNetCore.Mvc.CookieTempDataProvider=CfDJ8Bgf-3KbNAlFnw0qxXGLD9y9vjSRJBQKzODg4E63G6cJ1m7G10iOieGgjHaSxYMyTGIJXq63lok1qRnUbY4bhMVtx-FPDHuDYk5XCO07GwoiXlv4fOS29BnjWBOhe3KbH9AN5r95ISXA2OA0qU2_RyM; ai_user=1ij7F7ebDm9/Yqs10VvAdR|2023-08-19T02:56:46.703Z; ARRAffinity=18efd627f8956a7b2f7fe48e9a288fd80504b2e801f6ffc58e7351c5d0f1908e; ARRAffinitySameSite=18efd627f8956a7b2f7fe48e9a288fd80504b2e801f6ffc58e7351c5d0f1908e; ai_session=iO35WKnNqqQ5ra5CHxaV/4|1694649176707|1694649204176',
          'Origin': 'https://copasaportalprd.azurewebsites.net',
          'Referer': 'https://copasaportalprd.azurewebsites.net/Copasa.Portal/Login/Index',
          'Request-Id': '|cd284e472e6a43df9add034bb7c78e85.dff3520c9b184b6d',
          'Sec-Fetch-Dest': 'empty',
          'Sec-Fetch-Mode': 'cors',
          'Sec-Fetch-Site': 'same-origin',
          'User-Agent': 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36',
          'X-Requested-With': 'XMLHttpRequest',
          'sec-ch-ua': '"Chromium";v="116", "Not)A;Brand";v="24", "Google Chrome";v="116"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"Linux"',
          'traceparent': '00-cd284e472e6a43df9add034bb7c78e85-dff3520c9b184b6b-01'
        },
        body: 'username=&password=&adxusername=60239022351&adxpassword=Jw190390!&url=https%3A%2F%2Fcopasaproddyn365api.azurewebsites.net'
      });

      const data = await response.json();
      setResponseMessage(JSON.stringify(data, null, 2));
    } catch (error) {
      setResponseMessage('Erro ao fazer a solicitação: ' + error.message);
    }
  };

  return (
    <div>
      <h1>Exemplo de Solicitação POST com React</h1>
      <button onClick={handleRequest}>Fazer Solicitação POST</button>
      <pre>{responseMessage}</pre>
    </div>
  );
}

export default Caema;