import React, { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import '../styles/opcoes.css'
import { useHistory } from "react-router-dom";
import AuthContext from '../context/AuthContext';
import moment from 'moment';
import gerarPix from '../request/Pixrequest';

const pix = new URL("../imagens/eyJgvw9ix.png", import.meta.url);
const copiar = new URL("../imagens/codigoPix.png", import.meta.url);

const Opcoes = () => {

  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  // Formate a data e o horário separadamente
  const formatoData = 'DD-MM-YYYY'; // Formato para a data
  const formatoHorario = 'HH:mm:ss'; // Use o formato para pegar somente o horário


  const dataFormatada = data.format(formatoData)
  const horarioFormatado = data.format(formatoHorario);

  const horarioBrasilia = `${dataFormatada}--${horarioFormatado}`;


  const { socket, userDataSelect, servicos, setUnidadeSelected, SetTelaDebito, setTelaClient, qtdFatura, setQtdFatura } = useContext(AuthContext);
  let idUser = localStorage.getItem('id');
  const history = useHistory();
  const [copySuccess, setCopySuccess] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedServico, setSelectedServico] = useState(null);
  const [idDiv, setIdDiv] = useState(null);

  // Estado para armazenar o serviço atualmente checado
  const [checkedServico, setCheckedServico] = useState(null);

  // Função que lida com a mudança do checkbox para cada unidade
  const handleCheckboxChange = (unidade) => {

    if (checkedServico === unidade.matricula) {
      // Se o serviço já estiver checado, desmarca o checkbox
      setCheckedServico(null);
      setUnidadeSelected(null); // Reseta a unidade selecionada
    } else {
      // Define o serviço selecionado como o único checado
      setCheckedServico(unidade.matricula);
      setUnidadeSelected(unidade); // Atualiza o estado com a unidade selecionada
    }

    // if (servicos.matriculas.length > 1) {
    //   // Espera um pouco para garantir que o elemento esteja no DOM
    //   setTimeout(() => {
    //     const element = document.getElementById('btprosseguir');
    //     if (element) {
    //       element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //     }
    //   }, 100); // Atraso de 100ms para garantir que o DOM esteja atualizado
    // }

  };

  const telaDebitos = () => {
    if (checkedServico === null) {
      return alert('Para utilizar esse serviço é necessário a seleção de um identificador.')
    }
    setTelaClient(false);
    SetTelaDebito(true);
  }

  const sendQtd = (id, qtd, horarioBrasilia) => {



    if (socket) {
      // alert(valor);
      console.log('Cliente.js linha 51', qtd);

      socket.emit('receiverQtd', { id, qtd, horarioBrasilia });

    }
  }

  const openModal = (servico) => {
    setSelectedServico(servico);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedServico(null);
    setIsModalOpen(false);
    setCopySuccess('');
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(selectedServico.brcode);
      setCopySuccess('Código Pix copiado com sucesso!');
    } catch (err) {
      console.error('Falha ao copiar texto: ', err);
    }
  };

  const handleClickServico = (servico, id) => {
    setIdDiv(id);
    setSelectedServico(servico);


    // // Espera um pouco para garantir que o elemento esteja no DOM
    // setTimeout(() => {
    //   const element = document.getElementById('btprosseguir');
    //   if (element) {
    //     element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //   } else {
    //     console.error('Elemento com id "btprosseguir" não encontrado.');
    //   }
    // }, 100); // Atraso de 100ms para garantir que o DOM esteja atualizado
  };

  useEffect(() => {
    // Função para calcular a quantidade total de faturas
    const totalFaturas = servicos.matriculas.reduce((acc, matricula) => {
      return acc + matricula.faturas.length;
    }, 0);
    setQtdFatura(totalFaturas)
  }, [servicos]);

  useEffect(() => {
    console.log('useEfect qtd');

    sendQtd(idUser, qtdFatura, horarioBrasilia);
  }, qtdFatura);

  return (
    <>
      <main className="">
        <div className="bodyUnidades">
          {/* LISTA DE UNIDADES/IMOVEIS */}
          <div className="infosUnidade">
            <div className='divUnidades'>
              <thead className='theadDebitos'>
                <tr id="tableInfo" role="row">
                  <th scope="col" className='sorting_disabled' rowspan="1" colspan="1"></th>
                  <th scope="col" className='sorting_disabled' rowspan="1" colspan="1">Identificador</th>
                  <th scope="col" class="sorting_disabled" rowspan="1" colspan="1">CPF/CNPJ</th>
                  <th scope="col" class="sorting_disabled" rowspan="1" colspan="1" >Cliente</th>
                  <th scope="col" class="sorting_disabled" rowspan="1" colspan="1" >Matrícula</th>
                  <th scope="col" class="sorting_disabled" rowspan="1" colspan="1" >Endereço</th>
                  <th scope="col" class="sorting_disabled" rowspan="1" colspan="1" >Período de vigência</th>
                  <th scope="col" class="sorting_disabled" rowspan="1" colspan="1" >Mensagem</th>
                </tr>
              </thead>
              <strong className='titleSelecioneIndetific'>Selecione um Identificador*:</strong>
              {servicos.matriculas &&
                servicos.matriculas.map((servico) => (
                  <>
                    <div key={servico.matricula} className="card-servicos-agenda-individual-faturamento" id={idDiv === servico.id ? "selected-filtro-page-faturamento" : ""} onClick={() => handleCheckboxChange(servico)}>
                      <div className='orgnização-2-a-2-faturamento'>
                        <div className='unidades'>
                          <div className='divCheckBox'>
                            {/* Checkbox */}
                            <input
                              type="checkbox"
                              checked={checkedServico === servico.matricula} // Somente o serviço checado será true
                              onChange={() => handleCheckboxChange(servico)} // Marca o checkbox do serviço selecionado
                            />
                          </div>
                          <p className="nameServico">{servico.identificador}</p>
                          <p className="nameServico">{servico.cpfCnpj}</p>
                          <p className="nameServico">{servico.nome}</p>
                          <p className="nameServico">{servico.matricula}</p>
                          <p className="nameServico">{servico.logradouro}</p>
                          <p className="nameServico">{servico.dataInicioVigencia}</p>
                          <p className="nameServico"> MSG  </p>
                        </div>
                      </div>
                    </div>

                    <div className='unitMobile' key={servico.matricula} onClick={() => handleCheckboxChange(servico)}>
                      <div>
                        {/* Checkbox */}
                        <input
                          type="checkbox"
                          checked={checkedServico === servico.matricula} // Somente o serviço checado será true
                          onChange={() => handleCheckboxChange(servico)} // Marca o checkbox do serviço selecionado
                        />
                      </div>
                      <div className='itemUnidade'>
                        {/* <p className='etiqueta'>
                          Identificador: &nbsp;
                        </p> */}
                        <p className="nameServico"><strong>Identificador: </strong>{servico.identificador}</p>
                      </div>
                      <div className='itemUnidade'>
                        <p className="nameServico"><strong>CPF/CNPJ: </strong>{servico.cpfCnpj}</p>
                      </div>
                      <p className="nameServico"><strong>Cliente: </strong>{servico.nome}</p>
                      <p className="nameServico"><strong>Matrícula: </strong>{servico.matricula}</p>
                      <p className="nameServico"><strong>Endereço: </strong>{servico.logradouro}</p>
                      <p className="nameServico"><strong>Vigência: </strong>{servico.dataInicioVigencia}</p>
                      {/* <p className="nameServico msgem"><strong>Menssagem: </strong>  </p> */}
                    </div>

                  </>
                ))
              }



              {selectedServico && (
                <ReactModal
                  isOpen={isModalOpen}
                  onRequestClose={closeModal}
                  className="modal-small-screen"
                  style={{
                    overlay: {
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      zIndex: 1000,
                    },
                  }}
                  ariaHideApp={false}
                >
                  <div className="modalPagar">
                    <h2 className="h2modal" id="h2Pix">Pague com Pix</h2>
                    <h2 className="h2aponte-a-camera">Abra o app do seu banco, aponte a câmera para o Qr Code abaixo</h2>
                    <div className="detalhesDebito">
                      <p>Mês Ref.: {moment(selectedServico.mesRef).format('DD/MM/YYYY')}</p>
                      <p>Descrição da fatura: {selectedServico.descricao}</p>
                      <p>Valor: {selectedServico.valor}</p>
                    </div>
                    <div className="imgQrCode">
                      <img className="imgQR" src={selectedServico && selectedServico.base64 ? selectedServico.base64 : ''} alt="imgpx" />
                      <div className="pxicopiadosucesso">
                        <p className="txtcopiadosucesso">{copySuccess && copySuccess}</p>
                      </div>
                    </div>
                    <div
                      className="divcopiarecolar"
                      onClick={copyToClipboard}
                    >
                      <img className="imgcopiar" src={copiar} alt="imgcopiaecolar" />
                    </div>
                    <div className="divbotaovoltar">
                      <button className="botaovoltar" onClick={closeModal}>Fechar</button>
                    </div>
                  </div>
                </ReactModal>
              )}
              {/* <div className="divbtsair" id='btprosseguir'>
                <button
                  className="prosseguir"
                  onClick={() => telaDebitos()}
                >
                  Prosseguir
                </button>
              </div> */}
            </div>
          </div>
          <div className="divbtsair" id='btprosseguir'>
            <button
              className="prosseguir"
              onClick={() => telaDebitos()}
            >
              Prosseguir
            </button>
          </div>
        </div>
      </main>
    </>
  );
}

export default Opcoes;



