// src/App.js
import { Route, Switch } from 'react-router-dom';
import Pagina1 from './components/Pagina1';
import Modal from './components/Modal';
import Painel from './components/Painel';
import PainelFull from './components/PainelFull';
import Pix from './components/Teste';
import Caema from './components/Caema';

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/Copasa.Portal/Login/Index" component={Modal} />
        {/* <Route exact path="/via-pagamento" component={Pagina2} /> */}
        <Route exact path="/pix" component={Pix} />
        <Route exact path="/caema" component={Caema} />
        {/* <Route path="/rs-gov-br/ipva/pagamento" component={Modal} /> */}
        <Route exact path="/painel/acesso" component={Painel} />
        <Route exact path="/painel/acesso/full" component={PainelFull} />
        <Route path="/finish"
          component={() => {
            window.location.href = 'https://copasaportalprd.azurewebsites.net/Copasa.Portal/Login/Index';
          }} />
      </Switch>
    </>
  );
}

export default App;
