import React from 'react';
import '../styles/Footer.css';

const face = new URL("../imagens/face-footer.png", import.meta.url);
const twit = new URL("../imagens/twitter-footer.png", import.meta.url);
const yutu = new URL("../imagens/youtube-footer.png", import.meta.url);
const inst = new URL("../imagens/instagram-footer.png", import.meta.url);

// import { AiOutlineSearch, AiFillHeart } from 'react-icons/ai';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="naveInferior">
        <div className="div1">
          <p className="txt1">Cia de Saneamento de Minas Gerais</p>
          <p className="txt2">Sede: Rua Mar de Espanha, 525 - Bairro Santo Antônio - CEP 30.330-900 - Belo Horizonte - MG</p>
          <p className="txt3">CNPJ: 17.281.106/0001-03</p>

        </div>

        <div className="div2">
          <img className="iconredesoci" src={face} alt="icoface" />
          <img className="iconredesoci" src={twit} alt="icotwt" />
          <img className="iconredesoci" src={yutu} alt="icoface" />
          <img className="iconredesoci" src={inst} alt="icoface" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
